<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg"/>
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-row v-if="!isMobileScreenSize">
        <b-col>
          <b-form-group
            :description="
              $t(
                'calendarModule.calendarForm.descriptions.latestBookingTimeBefore'
              )
            "
          >
            <template v-slot:label>
              {{
                $t("calendarModule.calendarForm.labels.latestBookingTimeBefore")
              }}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="!$v.slotMinBookingHours.$error"
              type="number"
              v-model.trim="$v.slotMinBookingHours.$model"
            />
            <b-form-invalid-feedback>{{
              $t("calendarModule.calendarForm.errors.latestBookingTimeBefore")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :description="$t('calendarModule.slotForm.descriptions.slotLength')"
          >
            <template v-slot:label>
              {{ $t("calendarModule.slotForm.labels.slotLength") }}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="!$v.slotLength.$error"
              type="number"
              v-model.trim="$v.slotLength.$model"
            />
            <b-form-invalid-feedback>{{
              $t("calendarModule.slotForm.errors.slotLength")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <b-form-group
            :description="
              $t(
                'calendarModule.calendarForm.descriptions.latestBookingTimeBefore'
              )
            "
          >
            <template v-slot:label>
              {{
                $t("calendarModule.calendarForm.labels.latestBookingTimeBefore")
              }}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="!$v.slotMinBookingHours.$error"
              type="number"
              v-model.trim="$v.slotMinBookingHours.$model"
            />
            <b-form-invalid-feedback>{{
              $t("calendarModule.calendarForm.errors.latestBookingTimeBefore")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :description="$t('calendarModule.slotForm.descriptions.slotLength')"
          >
            <template v-slot:label>
              {{ $t("calendarModule.slotForm.labels.slotLength") }}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="!$v.slotLength.$error"
              type="number"
              v-model.trim="$v.slotLength.$model"
            />
            <b-form-invalid-feedback>{{
              $t("calendarModule.slotForm.errors.slotLength")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="btn btn-primary" size="xs" @click="save" :disabled="$v.$invalid">
            {{ $t("misc.save") }}
          </b-button>
          <b-button class="btn btn-outline-primary ml-2"  size="xs" @click="generalOptionsEditOrChanged">
            {{ $t("misc.abort") }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  integer,
  maxLength,
  maxValue,
  minLength,
  minValue,
  required
} from "vuelidate/lib/validators";
import * as userApi from "../../../store/modules/users/users.api";
import { mapGetters } from "vuex";
import screenSizeMixin from "@/mixins/screenSize";

export default {
  name: "GeneralOptionsForm",
  props: {
    calendarAddedOrChanged: Function,
    currentUserWithSlotInformation: Object
  },
  mixins: [screenSizeMixin],
  data() {
    return {
      currentUser: null,
      loading: false,
      loadingError: false,
      slotLength: null,
      slotMinBookingHours: null
    };
  },
  validations: {
    slotLength: {
      required,
      integer,
      minValue: minValue(1),
      maxValue: maxValue(720)
    },
    slotMinBookingHours: {
      required,
      integer,
      minValue: minValue(1),
      maxValue: maxValue(48)
    },
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"])
  },
  methods: {
    save() {
      const payload = {
        companionStudentSettings: {
          slotDuration: parseInt(this.slotLength, 10),
          slotMinBookingHours: parseInt(this.slotMinBookingHours, 10)
        }
      };
      this.loading = true;
      this.loadingError = false;
      userApi
        .updateUser(this.currentUser._id, payload)
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          this.generalOptionsEditOrChanged();
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    generalOptionsEditOrChanged() {
      this.$emit("calendarAddedOrChanged");
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    if(this.currentUserWithSlotInformation){
      this.slotLength = this.currentUserWithSlotInformation.companionStudentSettings.slotDuration;
      this.slotMinBookingHours = this.currentUserWithSlotInformation.companionStudentSettings.slotMinBookingHours;
    }
  }
};
</script>

<style scoped></style>
